import { Repository, Document } from 'sunflowers';
const repositoryName = "broadcastGroup";
export class BroadcastGroup extends Document {
    constructor(broadcastGroup) {
        super(repositoryName, broadcastGroup);
        this.name = broadcastGroup.name || "";
        this.weight = broadcastGroup.weight || 5;
        this.mode = broadcastGroup.mode || "ordered";
        this.broadcasts = !broadcastGroup.broadcasts ? [] : broadcastGroup.broadcasts.map(b => ({ ...b, objectFit: b.objectFit || "cover" }));
        this.start = new Date(broadcastGroup.start);
        this.end = broadcastGroup.end ? new Date(broadcastGroup.end) : null;
        this.screens = broadcastGroup.screens || [];
        this.screenGroups = broadcastGroup.screenGroups || [];
        this.timeSlots = broadcastGroup.timeSlots || null;
        this.playing = broadcastGroup.playing === false ? false : true;
    }
}
export class BroadcastGroups extends Repository {
    constructor(docs) {
        super(repositoryName, BroadcastGroups, BroadcastGroup, docs);
    }
}
export default new BroadcastGroups();
