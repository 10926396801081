import React from "react";
import { Button, Input, Row, Select, Table } from "sunflowers";
import { faPaperPlane, faPlus, faPen, faArchive } from "@fortawesome/free-solid-svg-icons";
import { User } from '../../models/User.model';
import "./style.scss";
import { ArchiveAndRemovedButtons } from "../../components/ArchiveAndRemovedButtons/ArchiveAndRemovedButtons";
import { superadminHead } from "../../utils/superadminHead";
import { OrganizationsSelect } from "../../components/OrganizationsSelect/OrganizationsSelect";
const Main = (view) => {
    const userForm = (user) => (React.createElement("div", null,
        React.createElement(Row, { flex: true },
            React.createElement(Input, { label: "E-mail", name: "userName", defaultValue: user?.userName, required: true }),
            React.createElement(Select, { values: [2, 3], labels: ["Administrateur", "Utilisateur"], name: "roles", label: "R\u00F4les", value: user?.roles, required: true, multiple: true })),
        React.createElement(Row, { flex: true },
            React.createElement(Input, { label: "Pr\u00E9nom", name: "firstName", defaultValue: user?.firstName, required: true }),
            React.createElement(Input, { label: "Nom", name: "lastName", defaultValue: user?.lastName, required: true })),
        React.createElement(OrganizationsSelect, { value: user?.organizations })));
    const edit = (user) => view.modal({
        form: userForm(user),
        resolveButton: "Sauvegarder",
        rejectButton: "Annuler",
        title: "Sauvegarder un utilisateur"
    })
        .then((patch) => {
        user.patch(patch)
            .send()
            .then(() => view.notify("Modifications sauvegardés", "green"))
            .catch(() => view.notify("Echec", "red"));
    });
    let head = [
        {
            key: "userName",
            label: "E-mail",
            sortable: true,
        },
        {
            key: "firstName",
            label: "Prénom",
            sortable: true,
        },
        {
            key: "lastName",
            label: "Nom",
            sortable: true,
        },
    ];
    head = superadminHead(head, view.repositories.organization, view.session.user);
    let mainHead = [
        ...head,
        {
            label: "",
            key: "sendAgain",
            type: "function",
            size: 4,
            function: (user) => (React.createElement(Button, { className: "actionButton", icon: faPaperPlane, filled: true, onClick: (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    view.modal({
                        title: "Relancer l'inscription",
                        rejectButton: "Annuler",
                        resolveButton: "Renvoyer",
                        message: (React.createElement(React.Fragment, null,
                            "Souhaitez vous renvoyer le mail d'inscription \u00E0 ",
                            user.firstName,
                            " ",
                            user.lastName,
                            " ?",
                            React.createElement("br", null),
                            "Cela lui permettra de cr\u00E9er ou sauvegarder son mot de passe.")),
                    })
                        .then(() => {
                        view.emit("resendSignupEmail", { userName: user.userName })
                            .then(() => view.notify("L'e-mail d'inscription a bien été renvoyé.", "green"))
                            .catch(() => view.notify("Erreur lors de l'envoi de l'email d'inscription.", "red"));
                    });
                } }))
        },
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (doc) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faPen, onClick: () => edit(doc) }))
        },
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (user) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faArchive, onClick: (event) => {
                    event.stopPropagation();
                    view.modal({
                        resolveButton: "Archiver",
                        rejectButton: "Annuler",
                        title: "Archiver un utilisateur"
                    })
                        .then(() => {
                        user.archive()
                            .send()
                            .then(() => view.notify("Utilisateur archivé.", "green"))
                            .catch(() => view.notify("Echec.", "red"));
                    });
                } }))
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "titleRow" },
            React.createElement("h3", null, "Utilisateurs"),
            React.createElement(ArchiveAndRemovedButtons, { head: head, repository: "user", filterArchives: (array) => array.filter(s => !s.roles.includes(4)), filterRemoved: (array) => array.filter(s => !s.roles.includes(4)), archivesTitle: "Utilisateurs archiv\u00E9s", removedTitle: "Utilisateurs supprim\u00E9s", unarchiveTitle: () => "Désarchiver l'utilisateur", onUnarchiveSuccess: () => view.notify("Utilisateur désarchivé.", "green"), onUnarchiveFail: () => view.notify("Echec.", "red"), removeTitle: () => "Supprimer l'utilisateur", onRemoveSuccess: () => view.notify("Utilisateur supprimé.", "green"), onRemoveFail: () => view.notify("Echec.", "red"), restoreTitle: () => "Restaurer l'utilisateur", onRestoreSuccess: () => view.notify("Utilisateur restauré.", "green"), onRestoreFail: () => view.notify("Echec.", "red"), destroyTitle: () => "Supprimer définitivement l'utilisateur", onDestroySuccess: () => view.notify("Utilisateur supprimé définitivement.", "green"), onDestroyFail: () => view.notify("Echec.", "red"), emptyTrashTitle: "Vider la corbeille d'utilisateurs ?", onEmptyTrashSuccess: () => view.notify("Corbeille vidée.", "green"), onEmptyTrashFail: () => view.notify("Echec.", "red") })),
        React.createElement("section", null,
            React.createElement(Button, { name: "nouvel utilisateur", icon: faPlus, className: "addButton", onClick: () => {
                    view.modal({
                        title: "Nouvel utilisateur",
                        form: userForm(),
                        rejectButton: "Annuler",
                        resolveButton: "Ajouter"
                    })
                        .then((user) => {
                        view.emit("signup", user)
                            .then(() => {
                            view.repositories.user.post(new User(user));
                            view.notify("Utilisateur ajouté", "green");
                        })
                            .catch(() => view.notify("Echec", "red"));
                    })
                        .catch(() => { });
                } }),
            React.createElement(Table, { array: view.repositories.user.filter(u => !u.roles.includes(4)), head: mainHead, flex: true, onClick: edit, remove: (user) => {
                    view.modal({
                        title: "Supprimer un utilisateur",
                        message: "Souhaitez-vous supprimer cet utilisateur ?",
                        rejectButton: "Annuler",
                        resolveButton: "Supprimer"
                    })
                        .then(() => {
                        user.remove()
                            .send()
                            .then(() => view.notify("Utilisateur supprimé.", "green"))
                            .catch(() => view.notify("Utilisateur.", "red"));
                    });
                } }))));
};
export default Main;
