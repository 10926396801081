import React from "react";
import { faPlus, faPen, faArchive } from "@fortawesome/free-solid-svg-icons";
import { Button, Input, Row, Select, Table } from "sunflowers";
import { ArchiveAndRemovedButtons } from "../../components/ArchiveAndRemovedButtons/ArchiveAndRemovedButtons";
import { ScreenGroup } from "../../models/ScreenGroup.model";
import "./style.scss";
import { superadminHead } from "../../utils/superadminHead";
import { OrganizationsSelect } from "../../components/OrganizationsSelect/OrganizationsSelect";
const Main = (view) => {
    const doc = view.repositories.broadcastGroup.findById("test");
    const form = (screengroup) => (React.createElement(React.Fragment, null,
        React.createElement(Row, null,
            React.createElement(Input, { label: "nom", name: "name", defaultValue: screengroup?.name })),
        React.createElement(Row, null,
            React.createElement(Select, { labels: view.repositories.user.filter(u => u.roles.includes(4)).map(s => s.userName), values: view.repositories.user.filter(u => u.roles.includes(4)).map(u => u._id), label: "Ecrans", name: "screens", multiple: true, value: screengroup?.screens })),
        React.createElement(OrganizationsSelect, { value: screengroup?.organizations })));
    const edit = (screengroup) => view.modal({
        form: form(screengroup),
        title: "Sauvegarder un groupe d'écrans",
        resolveButton: "Sauvegarder",
        rejectButton: "Annuler",
    })
        .then((patch) => {
        screengroup.patch(patch)
            .send()
            .then(() => view.notify("Modifications sauvegardées.", "green"))
            .catch(() => view.notify("Echec", "red"));
    });
    let head = [
        {
            label: "Nom",
            key: "name",
            type: "string",
            sortable: true,
        },
        {
            label: "Ecrans",
            key: "screens",
            type: "function",
            function: (sg) => {
                const screens = sg.getScreens(view.repositories.user);
                return screens.reduce((string, screen, i, a) => `${string}${screen.userName}${i < a.length - 1 ? ", " : ""}`, "");
            }
        }
    ];
    head = superadminHead(head, view.repositories.organization, view.session.user);
    let mainHead = [
        ...head,
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (doc) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faPen, onClick: edit }))
        },
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (screenGroup) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faArchive, onClick: (event) => {
                    event.stopPropagation();
                    view.modal({
                        resolveButton: "Archiver",
                        rejectButton: "Annuler",
                        title: "Archiver un groupe d'écrans"
                    })
                        .then(() => {
                        screenGroup.archive()
                            .send()
                            .then(() => view.notify("Groupe d'écrans archivé.", "green"))
                            .catch(() => view.notify("Echec.", "red"));
                    });
                } }))
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "titleRow" },
            React.createElement("h3", null, "Groupes d'\u00E9crans"),
            React.createElement(ArchiveAndRemovedButtons, { head: head, repository: "screenGroup", archivesTitle: "Groupes d'\u00E9crans archiv\u00E9s", removedTitle: "Groupes d'\u00E9crans supprim\u00E9s", unarchiveTitle: () => "Désarchiver le groupe d'écrans", onUnarchiveSuccess: () => view.notify("Groupe d'écran désarchivé.", "green"), onUnarchiveFail: () => view.notify("Echec.", "red"), removeTitle: () => "Supprimer le groupe d'écrans", onRemoveSuccess: () => view.notify("Groupe d'écrans supprimé.", "green"), onRemoveFail: () => view.notify("Echec.", "red"), restoreTitle: () => "Restaurer le groupe d'écran", onRestoreSuccess: () => view.notify("Groupe d'écrans restauré.", "green"), onRestoreFail: () => view.notify("Echec.", "red"), destroyTitle: () => "Supprimer définitivement le groupe d'écrans", onDestroySuccess: () => view.notify("Groupe d'écrans supprimé définitivement.", "green"), onDestroyFail: () => view.notify("Echec.", "red"), emptyTrashTitle: "Vider la corbeille de groupes d'écrans ?", onEmptyTrashSuccess: () => view.notify("Corbeille vidée.", "green"), onEmptyTrashFail: () => view.notify("Echec.", "red") })),
        React.createElement("section", null,
            React.createElement(Button, { name: "Nouveau groupe d'\u00E9crans", onClick: () => view.modal({
                    form: form(),
                    resolveButton: "ajouter",
                    rejectButton: "Annuler",
                    title: "Nouveau groupe d'écrans"
                })
                    .then((screengroup) => {
                    view.repositories.screenGroup.post(new ScreenGroup(screengroup))
                        .send()
                        .then(() => view.notify("Nouveau groupe d'écrans sauvegardé.", "green"))
                        .catch(() => view.notify("Echec", "red"));
                }), className: "addButton", filled: true, icon: faPlus }),
            React.createElement(Table, { array: view.repositories.screenGroup, head: mainHead, flex: true, onClick: edit, remove: (screengroup) => {
                    view.modal({
                        title: "Supprimer un grounpe d'écran",
                        message: "Souhaitez-vous supprimer ce groupe d'écrans ?",
                        rejectButton: "Annuler",
                        resolveButton: "Supprimer"
                    })
                        .then(() => {
                        screengroup.remove()
                            .send()
                            .then(() => view.notify("Groupe d'écrans supprimé.", "green"))
                            .catch(() => view.notify("Erreur", "red"));
                    });
                } }))));
};
export default Main;
