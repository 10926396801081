import React, { useContext, useEffect } from "react";
import { faArchive, faTrash, faBoxOpen, faChevronLeft, faTrashRestore, faBurn } from "@fortawesome/free-solid-svg-icons";
import { Button, Context, Row, Table } from "sunflowers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ArchiveAndRemovedButtons.scss";
export function ArchiveAndRemovedButtons(props) {
    const context = useContext(Context);
    return (React.createElement("div", { className: "ArchiveAndRemovedButtons" },
        React.createElement(Button, { className: "actionButton", icon: faArchive, filled: true, onClick: () => context.modal && context.modal({
                raw: true,
                className: "SyneviewModal ArchiveAndDeleteModal",
                form: (_r, reject) => React.createElement(ArchiveOrRemovedModal, Object.assign({ reject: reject, type: "archives" }, props))
            }) }),
        React.createElement(Button, { className: "actionButton", icon: faTrash, filled: true, onClick: () => context.modal && context.modal({
                raw: true,
                className: "SyneviewModal ArchiveAndDeleteModal",
                form: (_r, reject) => React.createElement(ArchiveOrRemovedModal, Object.assign({ reject: reject, type: "removed" }, props))
            }) })));
}
export function ArchiveOrRemovedModal(props) {
    const context = useContext(Context);
    const repository = !context.repositories ? null : context.repositories[props.repository];
    useEffect(() => {
        console.log("USE EFFECT", repository);
        if (props.type === "archives") {
            repository?.getArchives()
                .send()
                .catch(() => context.notify && context.notify("Erreur", "red"));
        }
        else {
            repository?.getRemoved()
                .send()
                .catch(() => context.notify && context.notify("Erreur", "red"));
        }
    }, []);
    let head = props.head;
    if (props.type === "archives") {
        head = [
            ...head,
            {
                label: "",
                type: 'function',
                key: "",
                size: 4,
                function: (doc) => (React.createElement(Button, { className: "actionButton", filled: true, icon: faBoxOpen, onClick: (event) => {
                        event.stopPropagation();
                        context.modal && context.modal({
                            title: props.unarchiveTitle(doc),
                            message: props.unarchiveMessage ? props.unarchiveMessage(doc) : undefined,
                            rejectButton: "Annuler",
                            resolveButton: "Désarchiver"
                        })
                            .then(() => {
                            doc.unarchive()
                                .send()
                                .then(() => {
                                props.onUnarchiveSuccess && props.onUnarchiveSuccess(doc);
                            })
                                .catch(() => props.onUnarchiveFail && props.onUnarchiveFail(doc));
                        });
                    } }))
            },
            {
                label: "",
                type: 'function',
                key: "",
                size: 4,
                function: (doc) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faTrash, onClick: (event) => {
                        event.stopPropagation();
                        context.modal && context.modal({
                            title: props.removeTitle(doc),
                            message: props.removeMessage ? props.removeMessage(doc) : undefined,
                            rejectButton: "Annuler",
                            resolveButton: "Supprimer"
                        })
                            .then(() => {
                            doc.remove()
                                .send()
                                .then(() => {
                                props.onRemoveSuccess && props.onRemoveSuccess(doc);
                            })
                                .catch(() => props.onRemoveFail && props.onRemoveFail(doc));
                        });
                    } }))
            }
        ];
    }
    if (props.type === "removed") {
        head = [
            ...head,
            {
                label: "",
                type: 'function',
                key: "",
                size: 4,
                function: (doc) => (React.createElement(Button, { className: "actionButton", filled: true, icon: faTrashRestore, onClick: (event) => {
                        event.stopPropagation();
                        context.modal && context.modal({
                            title: props.restoreTitle(doc),
                            message: props.restoreMessage ? props.restoreMessage(doc) : undefined,
                            rejectButton: "Annuler",
                            resolveButton: "Restaurer"
                        })
                            .then(() => {
                            doc.restore()
                                .send()
                                .then(() => {
                                props.onRestoreSuccess && props.onRestoreSuccess(doc);
                            })
                                .catch(() => props.onRestoreFail && props.onRestoreFail(doc));
                        });
                    } }))
            },
            {
                label: "",
                type: 'function',
                key: "",
                size: 4,
                function: (doc) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faBurn, onClick: (event) => {
                        event.stopPropagation();
                        context.modal && context.modal({
                            title: props.destroyTitle(doc),
                            message: props.destroyMessage ? props.destroyMessage(doc) : undefined,
                            rejectButton: "Annuler",
                            resolveButton: "Supprimer"
                        })
                            .then(() => {
                            doc.destroy()
                                .send()
                                .then(() => {
                                props.onDestroySuccess && props.onDestroySuccess(doc);
                            })
                                .catch(() => props.onDestroyFail && props.onDestroyFail(doc));
                        });
                    } }))
            }
        ];
    }
    if (repository) {
        const array = props.type === "archives"
            ? props.filterArchives
                ? props.filterArchives(repository.archives)
                : repository.archives
            : props.filterRemoved
                ? props.filterRemoved(repository.removed)
                : repository.removed;
        return (React.createElement("div", { className: "modalContent" },
            React.createElement("p", { className: "backButton pretitle", onClick: props.reject },
                React.createElement(FontAwesomeIcon, { icon: faChevronLeft }),
                "Retour"),
            React.createElement(Row, { className: "titleRow" },
                React.createElement("h3", null, props.type === "archives" ? props.archivesTitle : props.removedTitle)),
            array && array.length > 0 ? (React.createElement(React.Fragment, null,
                props.type === "removed" && (React.createElement(Row, null,
                    React.createElement(Button, { name: "Vider la corbeille", className: "actionButton", icon: faBurn, onClick: (event) => {
                            event.stopPropagation();
                            context.modal && context.modal({
                                title: props.emptyTrashTitle,
                                message: props.emptyTrashMessage ? props.emptyTrashMessage : undefined,
                                rejectButton: "Annuler",
                                resolveButton: "Confirmer"
                            })
                                .then(() => {
                                repository.destroy(array.map(doc => doc._id))
                                    .send()
                                    .then(() => {
                                    props.onEmptyTrashSuccess && props.onEmptyTrashSuccess();
                                })
                                    .catch(() => props.onEmptyTrashFail && props.onEmptyTrashFail());
                            });
                        } }))),
                React.createElement(Table, { array: array, head: head, flex: true })))
                : (React.createElement("div", null))));
    }
    else {
        return React.createElement("div", null);
    }
}
