import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Context } from "sunflowers";
import colors from "../../style/colors";
import views from '../../views';
import { getSVGPath } from './getSVGPath';
import "./LeftBarBackground.scss";
const ease = (t) => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
const LeftBarRef = React.createRef();
const animationDuration = 200;
let animation = false;
export const LeftBarBackground = (props) => {
    const context = useContext(Context);
    const getLinkIndex = () => {
        const menuViews = views.filter(v => v.menu
            && (!v.roles
                || v.roles.length === 0
                || v.roles.some(role => context.session?.user?.roles.includes(role))));
        const index = menuViews.findIndex(v => Array.isArray(v.path)
            ? v.path.includes(String(context.router?.path))
                ? "active"
                : ""
            : v.path === context.router?.path
                ? "active"
                : "");
        return index;
    };
    const [state, setState] = useState({
        path: "",
        rect: null,
        previousIndex: getLinkIndex(),
        currentIndex: getLinkIndex(),
        targetIndex: getLinkIndex(),
        lastIndexChangeDate: Date.now()
    });
    const stateRef = useRef(state);
    stateRef.current = state;
    const draw = useCallback(() => {
        if (LeftBarRef.current) {
            const rect = LeftBarRef.current.getBoundingClientRect();
            const path = getSVGPath(rect, stateRef.current.currentIndex);
            if (path !== stateRef.current.path
                || rect.width !== stateRef.current.rect?.width
                || rect.height !== stateRef.current.rect?.height) {
                setState({ ...stateRef.current, path, rect });
            }
        }
        if (stateRef.current.currentIndex !== stateRef.current.targetIndex) {
            const timeDelta = Date.now() - stateRef.current.lastIndexChangeDate;
            let timeDeltaRatio = timeDelta / animationDuration;
            timeDeltaRatio = timeDeltaRatio > 1 ? 1 : timeDeltaRatio;
            if (timeDeltaRatio !== 1) {
                const easedTime = ease(timeDeltaRatio);
                const indexDelta = stateRef.current.targetIndex - stateRef.current.previousIndex;
                const currentIndex = stateRef.current.previousIndex + (indexDelta * easedTime);
                console.log(timeDeltaRatio);
                setState({
                    ...stateRef.current,
                    currentIndex
                });
            }
            else {
                setState({
                    ...stateRef.current,
                    currentIndex: stateRef.current.targetIndex
                });
            }
        }
        window.requestAnimationFrame(draw);
    }, [
        LeftBarRef.current,
        LeftBarRef.current?.getBoundingClientRect().width,
        LeftBarRef.current?.getBoundingClientRect().height
    ]);
    const updateLinkIndex = useCallback((index) => {
        setState({
            ...stateRef.current,
            previousIndex: stateRef.current.currentIndex,
            targetIndex: index,
            lastIndexChangeDate: Date.now(),
        });
    }, []);
    useEffect(() => {
        updateLinkIndex(getLinkIndex());
    }, [
        context.router?.path,
        context.session?.user,
        context.router?._currentViewDeclaration.title
    ]);
    useEffect(draw, []);
    return (React.createElement("div", { className: "LeftBarBackground", ref: LeftBarRef },
        React.createElement("svg", { width: stateRef.current.rect?.width || 0, height: stateRef.current.rect?.height || 0, xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "Gradient", x1: "0", x2: "0.2", y1: "1", y2: "0" },
                    React.createElement("stop", { offset: "0%", "stop-color": colors.DarkPrimary1 }),
                    React.createElement("stop", { offset: "25%", "stop-color": colors.DarkPrimary1 }),
                    React.createElement("stop", { offset: "100%", "stop-color": colors.SecondaryColor1 }))),
            React.createElement("path", { d: stateRef.current.path, fill: "url(#Gradient)" }))));
};
