import React, { useContext } from "react";
import { Context, Select } from "sunflowers";
export const OrganizationsSelect = (props) => {
    const context = useContext(Context);
    if (context.session?.user && context.session?.user.roles.includes(1)) {
        return (React.createElement("section", null,
            React.createElement(Select, { values: context.repositories?.organization.getIdsMap() || [], labels: context.repositories?.organization.map(orga => orga.name) || [], label: "Organisations", name: "organizations", value: props.value, onChange: props.onChange, multiple: true })));
    }
    else {
        return React.createElement("div", null);
    }
};
