import axios from "axios";
import { Promise } from "true-promise";
const convertOuverturePeriode = (op) => !op ? null : ({
    Start: op.Start ? new Date(op.Start) : null,
    End: op.End ? new Date(op.End) : null,
    Length: op.Length ? parseInt(op.Length) : null
});
export function requestItems(url, filter_id, imageFilter) {
    return new Promise((resolve, reject) => {
        const imageFilteredArgs = imageFilter ? "&imageFilter=" + imageFilter : "";
        const pattern = /^((http|https):\/\/)/;
        url = !pattern.test(url) ? "https://" + url : url;
        axios.get(`${url}/api/items?id=${filter_id + imageFilteredArgs}`)
            .then(response => {
            const items = response.data.items.map(item => ({
                ...item,
                OuverturePeriodes: item.OuverturePeriodes.map(convertOuverturePeriode),
                NextOuverturePeriode: convertOuverturePeriode(item.NextOuverturePeriode),
                LastModified: item.LastModified ? new Date(item.LastModified) : null,
            }));
            resolve(items);
        })
            .catch(reject);
    });
}
