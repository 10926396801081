import { Repository, Document } from 'sunflowers';
const repositoryName = "screenGroup";
export class ScreenGroup extends Document {
    constructor(screenGroup) {
        super(repositoryName, screenGroup);
        this.getScreens = (users) => {
            return users.filter(u => this.screens.includes(u._id));
        };
        this.name = screenGroup.name || "";
        this.screens = screenGroup.screens || [];
    }
}
export class ScreenGroups extends Repository {
    constructor(docs) {
        super(repositoryName, ScreenGroups, ScreenGroup, docs);
    }
}
export default new ScreenGroups();
