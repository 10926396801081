import React, { useEffect } from "react";
import { faArchive, faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import { Button, Input, Row, Table } from "sunflowers";
import { Organization } from "../../models/Organization.model";
import "./style.scss";
import { ArchiveAndRemovedButtons } from "../../components/ArchiveAndRemovedButtons/ArchiveAndRemovedButtons";
import { RepositoryCount } from "../../components/RepositoryCount/RepositoryCount";
const orgaForm = (orga) => (React.createElement(React.Fragment, null,
    React.createElement(Input, { label: "nom", name: "name", value: orga?.name })));
const Main = (view) => {
    const edit = (orga) => view.modal({
        form: orgaForm(orga),
        resolveButton: "Sauvegarder",
        rejectButton: "Annuler",
        title: "Sauvegarder une organisation"
    })
        .then((patch) => {
        orga.patch(patch)
            .send()
            .then(() => view.notify("Modifications sauvegardés", "green"))
            .catch(() => view.notify("Echec", "red"));
    });
    useEffect(() => {
        view.repositories.user.getArchives().send();
        view.repositories.user.getRemoved().send();
        view.repositories.screenGroup.getArchives().send();
        view.repositories.screenGroup.getRemoved().send();
        view.repositories.broadcastGroup.getArchives().send();
        view.repositories.broadcastGroup.getRemoved().send();
        view.repositories.media.getArchives().send();
        view.repositories.media.getRemoved().send();
    }, []);
    const head = [
        {
            label: "nom",
            key: "name",
            type: "string",
            sortable: true,
        },
        {
            label: "Utilisateurs",
            key: "name",
            type: "function",
            function: (orga) => {
                const orgaUsers = view.repositories.user.filter(u => !u.roles.includes(4)
                    && u.organizations.includes(orga._id));
                const orgaArchivedUsers = view.repositories.user.archives.filter(u => !u.roles.includes(4)
                    && u.organizations.includes(orga._id));
                const orgaRemovedUsers = view.repositories.user.filter(u => !u.roles.includes(4)
                    && u.organizations.includes(orga._id));
                return (React.createElement(RepositoryCount, { repo: orgaUsers.length, archives: orgaArchivedUsers.length, removed: orgaRemovedUsers.length }));
            }
        },
        {
            label: "Ecrans",
            key: "name",
            type: "function",
            function: (orga) => {
                const orgaScreens = view.repositories.user.filter(s => s.roles.includes(4)
                    && s.organizations.includes(orga._id));
                const orgaArchivedScreens = view.repositories.user.archives.filter(s => s.roles.includes(4)
                    && s.organizations.includes(orga._id));
                const orgaRemovedScreens = view.repositories.user.removed.filter(s => s.roles.includes(4)
                    && s.organizations.includes(orga._id));
                return (React.createElement(RepositoryCount, { repo: orgaScreens.length, archives: orgaArchivedScreens.length, removed: orgaRemovedScreens.length }));
            }
        },
        {
            label: "Groupes d'écrans",
            key: "name",
            type: "function",
            function: (orga) => {
                const orgaScreensGroups = view.repositories.screenGroup.filter(sg => sg.organizations.includes(orga._id));
                const orgaArchivedScreensGroups = view.repositories.screenGroup.archives.filter(sg => sg.organizations.includes(orga._id));
                const orgaRemovedScreensGroups = view.repositories.screenGroup.removed.filter(sg => sg.organizations.includes(orga._id));
                return (React.createElement(RepositoryCount, { repo: orgaScreensGroups.length, archives: orgaArchivedScreensGroups.length, removed: orgaRemovedScreensGroups.length }));
            }
        },
        {
            label: "Groupes de diffusions",
            key: "name",
            type: "function",
            function: (orga) => {
                const orgaBroadcastsGroups = view.repositories.broadcastGroup.filter(bg => bg.organizations.includes(orga._id));
                const orgaArchivedBroadcastsGroups = view.repositories.broadcastGroup.archives.filter(bg => bg.organizations.includes(orga._id));
                const orgaRemovedBroadcastsGroups = view.repositories.broadcastGroup.removed.filter(bg => bg.organizations.includes(orga._id));
                return (React.createElement(RepositoryCount, { repo: orgaBroadcastsGroups.length, archives: orgaArchivedBroadcastsGroups.length, removed: orgaRemovedBroadcastsGroups.length }));
            }
        },
        {
            label: "Médias",
            key: "name",
            type: "function",
            function: (orga) => {
                const orgaMedias = view.repositories.media.filter(m => m.organizations.includes(orga._id));
                const orgaArchivedMedias = view.repositories.media.archives.filter(m => m.organizations.includes(orga._id));
                const orgaRemovedMedias = view.repositories.media.removed.filter(m => m.organizations.includes(orga._id));
                return (React.createElement(RepositoryCount, { repo: orgaMedias.length, archives: orgaArchivedMedias.length, removed: orgaRemovedMedias.length }));
            }
        }
    ];
    const mainHead = [
        ...head,
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (doc) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faPen, onClick: () => edit(doc) }))
        },
        {
            label: "",
            type: 'function',
            key: "",
            size: 4,
            function: (orga) => (React.createElement(Button, { className: "actionButton", filled: true, icon: faArchive, onClick: (event) => {
                    event.stopPropagation();
                    view.modal({
                        title: "Archiver une organisation",
                        message: `Souhaitez vous archiver l'organisation ${orga.name} ?`,
                        rejectButton: "Annuler",
                        resolveButton: "Archiver"
                    })
                        .then(() => {
                        orga.archive()
                            .send()
                            .then(() => view.notify("Organisation archivée."))
                            .catch(() => view.notify("Erreur", "red"));
                    });
                } }))
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "titleRow" },
            React.createElement("h3", null, "Organisations"),
            React.createElement(ArchiveAndRemovedButtons, { head: head, repository: "organization", archivesTitle: "Organisations archiv\u00E9s", removedTitle: "Organisations supprim\u00E9s", unarchiveTitle: () => "Désarchiver l'organisation", onUnarchiveSuccess: () => view.notify("Organisations désarchivée.", "green"), onUnarchiveFail: () => view.notify("Echec.", "red"), removeTitle: () => "Supprimer l'organisation", onRemoveSuccess: () => view.notify("Organisations supprimée.", "green"), onRemoveFail: () => view.notify("Echec.", "red"), restoreTitle: () => "Restaurer l'organisation", onRestoreSuccess: () => view.notify("Organisations restaurée.", "green"), onRestoreFail: () => view.notify("Echec.", "red"), destroyTitle: () => "Supprimer définitivement l'organisation", onDestroySuccess: () => view.notify("Organisations supprimée définitivement.", "green"), onDestroyFail: () => view.notify("Echec.", "red"), emptyTrashTitle: "Vider la corbeille d'organisations ?", onEmptyTrashSuccess: () => view.notify("Corbeille vidée.", "green"), onEmptyTrashFail: () => view.notify("Echec.", "red") })),
        React.createElement("section", null,
            React.createElement(Button, { name: "nouvelle organisation", icon: faPlus, className: "addButton", onClick: () => {
                    view.modal({
                        title: "Nouvelle organisation",
                        form: orgaForm(),
                        rejectButton: "Annuler",
                        resolveButton: "Ajouter"
                    })
                        .then((orga) => {
                        view.repositories.organization.post(new Organization(orga))
                            .send()
                            .then(() => view.notify("Nouvelle organisation sauvegardée.", "green"))
                            .catch(() => view.notify("Echec", "red"));
                    });
                } }),
            React.createElement(Table, { array: view.repositories.organization, head: mainHead, flex: true, onClick: edit, remove: (user) => {
                    view.modal({
                        title: "Supprimer une organisation",
                        message: "Souhaitez-vous supprimer cette organisation ?",
                        rejectButton: "Annuler",
                        resolveButton: "Supprimer"
                    })
                        .then(() => {
                        user.remove()
                            .send()
                            .then(() => view.notify("Organisation supprimée.", "green"))
                            .catch(() => view.notify("Organisation.", "red"));
                    });
                } }))));
};
export default Main;
