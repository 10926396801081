import user from "./User.model";
import organization from "./Organization.model";
import media from "./Media.model";
import broadcastGroup from "./BroadcastGroup.model";
import screenGroup from "./ScreenGroup.model";
export default {
    user,
    organization,
    media,
    broadcastGroup,
    screenGroup
};
