import { Repository, Document } from 'sunflowers';
const repositoryName = "organization";
export class Organization extends Document {
    constructor(orga) {
        super(repositoryName, orga);
        this.name = orga.name || "";
        this.synapiURL = orga.synapiURL || "";
    }
}
export class Organizations extends Repository {
    constructor(docs) {
        super(repositoryName, Organizations, Organization, docs);
    }
}
export default new Organizations();
