export function getVideoDuration(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
            URL.revokeObjectURL(video.src);
            if (video.duration) {
                const duration = Math.round(video.duration * 1000) / 1000;
                resolve(duration);
            }
            else {
                reject();
            }
        };
        video.src = URL.createObjectURL(file);
        ;
    });
}
