import React, { useContext, useState } from "react";
import { Button, CheckBox, Context, DatePicker, Input, Number, Row, Select, Table } from "sunflowers";
import { TimeSlotsPicker } from "../../../components/TimeSlotsPicker/TimeSlotsPicker";
import { faPlus, faPen } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { mediasLibraryModal } from "../../../components/MediasLibrary/MediasLibrary";
import "./BroadcastGroupForm.scss";
import { MediaComponent } from "../../../components/MediaComponent/MediaComponent";
import { OrganizationsSelect } from "../../../components/OrganizationsSelect/OrganizationsSelect";
const weights = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const weightsLabels = ["1 (diffusé moins souvent)", 2, 3, 4, 5, 6, 7, 8, 9, "10 (diffusé plus souvent)"];
export const BroadcastGroupForm = (resolve, reject, broadcastGroup) => {
    const context = useContext(Context);
    const [state, setState] = useState({
        broadcastGroup: {
            name: broadcastGroup?.name || "",
            weight: broadcastGroup?.weight || 5,
            mode: broadcastGroup?.mode || "ordered",
            broadcasts: broadcastGroup?.broadcasts || [],
            start: broadcastGroup?.start || new Date(),
            end: broadcastGroup?.end || broadcastGroup?.start || new Date(),
            timeSlots: broadcastGroup?.timeSlots || [],
            screens: broadcastGroup?.screens || [],
            screenGroups: broadcastGroup?.screenGroups || [],
        },
        endDateEnabled: broadcastGroup?.end ? true : false,
        timeSlotsEnabled: broadcastGroup && broadcastGroup.timeSlots && broadcastGroup.timeSlots.length > 0 ? true : false
    });
    if (context.repositories) {
        function update(patch) {
            setState({ ...state, broadcastGroup: { ...state.broadcastGroup, ...patch } });
        }
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() + 2);
        const screenGroupsTableHead = [
            {
                label: "Nom",
                key: "name",
                type: "function",
                function: (screenGroupID) => {
                    const screen = context.repositories?.screenGroup.findById(screenGroupID);
                    return screen ? screen.name : "erreur";
                }
            },
            {
                label: "Ecrans",
                key: "screens",
                type: "function",
                size: 5,
                function: (screenGroupID) => {
                    const screenGroup = context.repositories?.screenGroup.findById(screenGroupID);
                    return screenGroup ? String(screenGroup.screens.length) : "erreur";
                }
            },
            {
                label: "",
                key: "",
                size: 4,
                type: "function",
                function: (screenGroupID) => (React.createElement(Button, { icon: faMinus, onClick: () => {
                        update({ screenGroups: state.broadcastGroup.screenGroups.filter(sg => sg !== screenGroupID) });
                    } }))
            }
        ];
        const screensTableHead = [
            {
                label: "Nom",
                key: "name",
                type: "function",
                function: (screenID) => {
                    const screen = context.repositories?.user.findById(screenID);
                    return screen ? screen.userName : "erreur";
                }
            },
            {
                label: "",
                key: "",
                size: 4,
                type: "function",
                function: (screenID) => (React.createElement(Button, { icon: faMinus, onClick: () => {
                        update({ screens: state.broadcastGroup.screens.filter(s => s !== screenID) });
                    } }))
            }
        ];
        const broadcastsHead = [
            {
                label: "",
                key: "media",
                type: "function",
                size: 6,
                function: (b) => {
                    const media = context.repositories?.media.findById(b.media);
                    if (media) {
                        return (React.createElement("div", { className: "mediaCell" },
                            React.createElement(MediaComponent, { media: media, library: true })));
                    }
                    else
                        return "";
                }
            },
            {
                label: "Média",
                key: "name",
                type: "function",
                function: (b) => {
                    const media = context.repositories?.media.findById(b.media);
                    return media?.name || "";
                }
            },
            {
                label: "Durée (en secondes)",
                key: "duration",
                type: "function",
                function: (b) => String(Math.floor(b.duration))
            },
            {
                label: "Format",
                key: "objectFit",
                type: "table",
                table: {
                    "cover": "Etirer",
                    "contain": "Adapter"
                }
            },
            {
                label: "",
                key: "",
                size: 4,
                type: "function",
                function: (bc) => {
                    return (React.createElement(Button, { icon: faPen, onClick: () => broadcastForm(bc) }));
                }
            },
            {
                label: "",
                key: "",
                size: 4,
                type: "function",
                function: (bc) => {
                    return (React.createElement(Button, { icon: faMinus, onClick: (event) => {
                            event.stopPropagation();
                            update({ broadcasts: state.broadcastGroup.broadcasts.filter((b) => !(bc.duration === b.duration
                                    && bc.media === b.media)) });
                        } }));
                }
            }
        ];
        const remainingScreens = context.repositories?.user.filter(u => u.roles.includes(4)).filter(u => !state.broadcastGroup.screens.includes(u._id));
        const remainingScreenGroups = context.repositories?.screenGroup.filter(screenGroup => !state.broadcastGroup.screenGroups.includes(screenGroup._id));
        const broadcastForm = (broadcast) => context.modal && context.modal((resolve, reject) => React.createElement(BroadcastModal, { resolve: resolve, reject: reject, broadcast: broadcast }))
            .then(bcPatch => {
            update({
                broadcasts: state.broadcastGroup.broadcasts.map(b => {
                    if (broadcast.duration === b.duration
                        && broadcast.media === b.media) {
                        return bcPatch;
                    }
                    else {
                        return b;
                    }
                })
            });
        });
        return (React.createElement("div", { className: "BroadcastGroupForm" },
            React.createElement("h3", null, "Nouveau groupe de diffusions"),
            React.createElement(Row, { flex: true },
                React.createElement(Input, { label: "nom", name: "name", value: broadcastGroup?.name, onInputChange: (name) => update({ name }) })),
            React.createElement(Row, { flex: true },
                React.createElement(Select, { values: weights, labels: weightsLabels.map(w => String(w)), label: "Poids", name: "weight", onChange: (weight) => update({ weight }), value: state.broadcastGroup.weight }),
                React.createElement(DatePicker, { value: state.broadcastGroup.start, label: "Date de d\u00E9but", name: "start", bottom: true, start: new Date(), end: maxDate, onChange: start => update({ start }) })),
            React.createElement(Row, { flex: true },
                React.createElement(CheckBox, { name: "endDateEnabled", label: "Date de fin", value: state.endDateEnabled, onChange: (endDateEnabled) => setState({ ...state, endDateEnabled }) }),
                React.createElement("div", { className: `endDate ${state.endDateEnabled ? "shown" : "hidden"}` },
                    React.createElement(DatePicker, { value: state.broadcastGroup.end, label: "Date de fin", name: "end", top: true, start: state.broadcastGroup.start || new Date(), end: maxDate, onChange: end => update({ end }) }))),
            React.createElement(Row, { flex: true },
                React.createElement(CheckBox, { name: "timeSlotsEnabled", label: "Cr\u00E9neaux horaires", value: state.timeSlotsEnabled, onChange: (timeSlotsEnabled) => setState({ ...state, timeSlotsEnabled }) })),
            state.timeSlotsEnabled && (React.createElement(Row, null,
                React.createElement(TimeSlotsPicker, { timeSlots: state.broadcastGroup.timeSlots || [], onChange: (timeSlots) => update({ timeSlots }) }))),
            React.createElement(Row, { className: "titleRow" },
                React.createElement("h5", { className: "subtitle" }, "Ecrans")),
            React.createElement(Row, { flex: true },
                React.createElement("div", null,
                    React.createElement("h5", { className: "pretitle" }, "Groupes d'\u00E9cran"),
                    React.createElement(Select, { label: "", name: "screenGroups", values: remainingScreenGroups.map(sg => sg._id), labels: remainingScreenGroups.map(sg => sg.name), value: false, onChange: (screenGroupID) => update({ screenGroups: [...state.broadcastGroup.screenGroups, screenGroupID] }), text: "Ajouter un groupe d'\u00E9crans" }),
                    state.broadcastGroup.screenGroups.length === 0 ? (React.createElement("p", { className: "emptyString" }, "Aucun groupe d'\u00E9cran s\u00E9lectionn\u00E9")) : (React.createElement(Table, { flex: true, array: state.broadcastGroup.screenGroups, head: screenGroupsTableHead }))),
                React.createElement("div", null,
                    React.createElement("h5", { className: "pretitle" }, "Ecrans"),
                    React.createElement(Select, { label: "", name: "screens", values: remainingScreens.map(s => s._id), labels: remainingScreens.map(s => s.userName), value: false, onChange: (screenID) => update({ screens: [...state.broadcastGroup.screens, screenID] }), text: "Ajouter un \u00E9cran" }),
                    state.broadcastGroup.screens.length === 0 ? (React.createElement("p", { className: "emptyString" }, "Aucun \u00E9cran s\u00E9lectionn\u00E9")) : (React.createElement(Table, { flex: true, array: state.broadcastGroup.screens, head: screensTableHead })))),
            React.createElement(Row, { className: "titleRow" },
                React.createElement("h4", { className: "subtitle rowTitle" }, "M\u00E9dias"),
                React.createElement(Button, { icon: faPlus, name: "", className: "actionButton", onClick: () => context.modal && context.modal((resolve, reject) => React.createElement(BroadcastModal, { resolve: resolve, reject: reject }))
                        .then(broadcast => {
                        update({ broadcasts: [...state.broadcastGroup.broadcasts, broadcast] });
                    }) }),
                React.createElement(CheckBox, { value: state.broadcastGroup.mode === "random", onChange: c => update({ mode: c ? "random" : "ordered" }), name: "mode", label: "Diffuser al\u00E9atoirement" })),
            React.createElement(Row, { flex: true },
                React.createElement(Table, { array: state.broadcastGroup.broadcasts, head: broadcastsHead, onClick: (b) => broadcastForm(b), flex: true })),
            React.createElement(OrganizationsSelect, { value: broadcastGroup?.organizations }),
            React.createElement(Row, { className: "buttonsRow" },
                React.createElement(Button, { name: "Annuler", className: "cancelButton", filled: true, onClick: reject }),
                React.createElement(Button, { name: broadcastGroup ? "sauvegarder" : "ajouter", className: "addButton", filled: true, onClick: () => {
                        resolve({
                            ...state.broadcastGroup,
                            timeSlots: !state.timeSlotsEnabled ? null : state.broadcastGroup.timeSlots,
                            end: !state.endDateEnabled ? null : state.broadcastGroup.end
                        });
                    } }))));
    }
    else
        return React.createElement("div", null);
};
const BroadcastModal = (props) => {
    const context = useContext(Context);
    const [state, setState] = useState({
        media: props.broadcast?.media || "",
        duration: props.broadcast?.duration || 15,
        objectFit: props.broadcast?.objectFit || "cover"
    });
    const [autoDuration, setAutoDuration] = useState(false);
    const media = context.repositories?.media.findById(state.media);
    return (React.createElement("div", { className: "BroadcastModal" },
        React.createElement(Row, null,
            React.createElement("h3", null, "Ajouter un m\u00E9dia")),
        React.createElement(Row, null,
            React.createElement(Button, { name: "S\u00E9lectionner un m\u00E9dia", className: "actionButton", onClick: () => context.modal && mediasLibraryModal(context.modal)
                    .then((media) => {
                    setState({ ...state, media: media._id });
                    if (media.type === "video") {
                        setAutoDuration(true);
                    }
                }) })),
        media && (React.createElement("div", { className: "media" },
            React.createElement(MediaComponent, { media: media, library: true }))),
        media?.type === "video" && (React.createElement(Row, null,
            React.createElement(CheckBox, { name: "autoDuration", label: "Dur\u00E9e automatique", value: autoDuration, onChange: (autoDuration) => setAutoDuration(autoDuration) }))),
        React.createElement(Row, { className: "titleRow" },
            React.createElement(Select, { values: ["cover", "contain"], labels: ["Etirer", "Adapter"], label: "Format", name: "objectFit", onChange: (objectFit) => setState({ ...state, objectFit }), value: state.objectFit }),
            !autoDuration && (React.createElement(Number, { label: "Dur\u00E9e (secondes)", name: "duration", value: String(state.duration), onInputChange: (duration) => setState({ ...state, duration: parseInt(duration, 10) }) }))),
        React.createElement(Row, { className: "buttonsRow" },
            React.createElement(Button, { name: "Annuler", className: "cancelButton", filled: true, onClick: props.reject }),
            React.createElement(Button, { name: props.broadcast ? "sauvegarder" : "ajouter", className: "addButton", filled: true, onClick: () => {
                    props.resolve({
                        ...state,
                        duration: autoDuration ? media.duration : state.duration
                    });
                } }))));
};
