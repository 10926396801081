import React from "react";
import { faArchive, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./RepositoryCount.scss";
export const RepositoryCount = (props) => {
    return (React.createElement("p", { className: "RepositoryCount" },
        props.repo,
        React.createElement("span", null,
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faArchive }),
                props.archives),
            React.createElement("span", null,
                React.createElement(FontAwesomeIcon, { icon: faTrash }),
                props.removed))));
};
