import React from "react";
import { Button, Input } from "sunflowers";
import "./style.scss";
const Main = (view) => {
    return (React.createElement("div", { className: "ConnexionView" },
        React.createElement("div", null,
            React.createElement("h3", null, "Connexion"),
            React.createElement(Input, { label: "e-mail", name: "username", value: view.store.username, onInputChange: (username) => view.update({ username }) }),
            React.createElement(Input, { label: "mot de passe", name: "password", value: view.store.password, onInputChange: (password) => view.update({ password }), type: "password" }),
            React.createElement(Button, { name: "connexion", onClick: () => {
                    view.session.login(view.store.username, view.store.password)
                        .then(() => {
                        view.router.navigate("");
                    })
                        .catch(() => { });
                } }))));
};
export default Main;
