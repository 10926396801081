import Screens from "./Screens";
import ScreenGroups from "./ScreenGroups";
import Medias from "./Medias";
import Users from "./Users";
import Superadmin from "./Superadmin";
import Connexion from "./Connexion";
import Broadcast from "./Broadcast";
import { faPhotoVideo, faTv, faUsers, faCrown, faNetworkWired, faBroadcastTower } from "@fortawesome/free-solid-svg-icons";
const views = [
    {
        path: "superadmin",
        view: Superadmin,
        title: "Superadmin",
        className: "Superadmin",
        roles: [1],
        menu: true,
        auth: true,
        icon: faCrown
    },
    {
        path: ["", "/", "broadcast"],
        view: Broadcast,
        title: "Diffusions",
        className: "Broadcast",
        menu: true,
        auth: true,
        icon: faBroadcastTower
    },
    {
        path: "bibliotheque",
        view: Medias,
        title: "Bibliothèque",
        auth: true,
        className: "Bibliotheque",
        menu: true,
        icon: faPhotoVideo
    },
    {
        path: "screens",
        view: Screens,
        title: "Ecrans",
        className: "Screens",
        menu: true,
        auth: true,
        icon: faTv
    },
    {
        path: "screenGroups",
        view: ScreenGroups,
        title: "Groupes d'écrans",
        auth: true,
        className: "ScreenGroups",
        menu: true,
        icon: faNetworkWired
    },
    {
        path: "users",
        view: Users,
        title: "Utilisateurs",
        className: "Users",
        menu: true,
        auth: true,
        icon: faUsers,
        roles: [1, 2]
    },
    {
        path: "connexion",
        view: Connexion,
        title: "Connexion",
        className: "Connexion",
        default: true
    },
];
export default views;
