import React, { useContext } from "react";
import { Button, Context } from "sunflowers";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import "./Profile.scss";
export function Profile() {
    const context = useContext(Context);
    if (context.session?.user) {
        const orgas = context.repositories?.organization.filter(o => context.session?.user.organizations.includes(o._id));
        return (React.createElement("div", { className: "Profile", onClick: () => context.modal && context.modal({
                raw: true,
                className: "SyneviewModal",
                form: (resolve, reject) => React.createElement(ProfileModal, { resolve: resolve, reject: reject, session: context.session, router: context.router })
            })
                .then(() => { })
                .catch(() => { }) },
            React.createElement(Button, { icon: faCog, className: "settingsButton" }),
            React.createElement("div", { className: "picture" },
                React.createElement("span", null,
                    context.session.user.firstName ? context.session.user.firstName[0]?.toUpperCase() : "",
                    context.session.user.lastName ? context.session.user.lastName[0]?.toUpperCase() : "")),
            React.createElement("p", { className: "name" },
                context.session.user.firstName,
                " ",
                context.session.user?.lastName.toUpperCase()),
            orgas && orgas.length > 0 && (React.createElement("p", { className: "organizations" }, orgas.map(orga => (React.createElement(React.Fragment, null,
                React.createElement("span", null, orga.name),
                React.createElement("br", null)))))),
            context.session.user.roles.length > 0 && (React.createElement("p", { className: "roles" }, context.session.user.roles.map(role => (React.createElement(React.Fragment, null,
                React.createElement("span", null, role === 1
                    ? "Superadmin"
                    : role === 2
                        ? "Administrateur·rice"
                        : role === 3
                            ? "Utilisateur"
                            : ""),
                React.createElement("br", null))))))));
    }
    else {
        return (React.createElement("div", null));
    }
}
function ProfileModal(props) {
    return (React.createElement("div", { className: "modalContent" },
        React.createElement(Button, { name: "Me d\u00E9connecter", onClick: () => {
                props.session?.logout();
                props.router?.navigate("connexion");
                props.reject();
            } })));
}
