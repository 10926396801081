import React, { useContext, useRef, useState } from "react";
import { faPlus, faPen, faArchive, faChevronRight, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Button, Context, Input, Number, Row, Table } from "sunflowers";
import { ArchiveAndRemovedButtons } from "../../components/ArchiveAndRemovedButtons/ArchiveAndRemovedButtons";
import { User } from '../../models/User.model';
import "./style.scss";
import { superadminHead } from "../../utils/superadminHead";
import { OrganizationsSelect } from "../../components/OrganizationsSelect/OrganizationsSelect";
import { TypeSelect } from "../../components/TypeSelect/TypeSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PositionSelector } from "../../components/PositionSelector/PositionSelector";
import { FormButtons } from "../../components/FormButtons/FormButtons";
import { WidgetComponent } from "../../components/Widget/Widget";
const Main = (view) => {
    const edit = (user) => view.modal({
        form: (resolve, reject) => React.createElement(ScreenModal, { resolve: resolve, reject: reject, screen: user }),
        raw: true,
        className: "SyneviewModal"
    })
        .then((patch) => {
        user.patch(patch)
            .send()
            .then(() => view.notify("Modifications sauvegardés", "green"))
            .catch(() => view.notify("Echec", "red"));
    });
    let head = [
        {
            label: "Nom",
            key: "userName",
            type: "string",
            sortable: true,
        },
        {
            label: "Clé",
            key: "screenKey",
            type: "string"
        },
    ];
    head = superadminHead(head, view.repositories.organization, view.session.user);
    let mainHead = [
        ...head,
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (doc) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faPen, onClick: edit }))
        },
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (screen) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faArchive, onClick: (event) => {
                    event.stopPropagation();
                    view.modal({
                        resolveButton: "Archiver",
                        rejectButton: "Annuler",
                        title: "Archiver un écran"
                    })
                        .then(() => {
                        screen.archive()
                            .send()
                            .then(() => view.notify("Ecran archivé.", "green"))
                            .catch(() => view.notify("Echec.", "red"));
                    });
                } }))
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "titleRow" },
            React.createElement("h3", null, "Ecrans"),
            React.createElement(ArchiveAndRemovedButtons, { head: head, repository: "user", filterArchives: (array) => array.filter(s => s.roles.includes(4)), filterRemoved: (array) => array.filter(s => s.roles.includes(4)), archivesTitle: "Ecrans archiv\u00E9s", removedTitle: "Ecrans supprim\u00E9s", unarchiveTitle: () => "Désarchiver l'écran", onUnarchiveSuccess: () => view.notify("Ecran désarchivé.", "green"), onUnarchiveFail: () => view.notify("Echec.", "red"), removeTitle: () => "Supprimer l'écran", onRemoveSuccess: () => view.notify("Ecran supprimé.", "green"), onRemoveFail: () => view.notify("Echec.", "red"), restoreTitle: () => "Restaurer l'écran", onRestoreSuccess: () => view.notify("Ecran restauré.", "green"), onRestoreFail: () => view.notify("Echec.", "red"), destroyTitle: () => "Supprimer définitivement l'écran", onDestroySuccess: () => view.notify("Ecran supprimé définitivement.", "green"), onDestroyFail: () => view.notify("Echec.", "red"), emptyTrashTitle: "Vider la corbeille d'écrans ?", onEmptyTrashSuccess: () => view.notify("Corbeille vidée.", "green"), onEmptyTrashFail: () => view.notify("Echec.", "red") })),
        React.createElement("section", null,
            React.createElement(Button, { name: "Nouvel \u00E9cran", onClick: () => view.modal({
                    form: (resolve, reject) => React.createElement(ScreenModal, { resolve: resolve, reject: reject }),
                    raw: true,
                    className: "SyneviewModal"
                })
                    .then((user) => {
                    view.repositories.user.post(new User({
                        ...user,
                        roles: [4],
                    }))
                        .send()
                        .then((response) => {
                        view.notify("Ecran sauvegardé", "green");
                    })
                        .catch(() => view.notify("Echec", "red"));
                }), className: "addButton", filled: true, icon: faPlus }),
            React.createElement(Table, { array: view.repositories.user.filter(u => u.roles.includes(4)), head: mainHead, flex: true, onClick: edit, remove: (user) => {
                    view.modal({
                        title: "Supprimer un écran",
                        message: "Souhaitez-vous supprimer cet écran ?",
                        rejectButton: "Annuler",
                        resolveButton: "Supprimer"
                    })
                        .then(() => {
                        user.remove()
                            .send()
                            .then(() => view.notify("Ecran supprimé.", "green"))
                            .catch(() => view.notify("Echec.", "red"));
                    });
                } }))));
};
export default Main;
const ScreenModal = (props) => {
    const context = useContext(Context);
    const [state, setState] = useState(props.screen || new User({
        userName: "",
        widgets: []
    }));
    const stateRef = useRef(state);
    stateRef.current = state;
    const widgetsHead = [
        {
            key: "type",
            type: "string",
            label: "type",
        },
        {
            label: "",
            key: "",
            size: 4,
            type: "function",
            function: (widget, widgetIndex) => {
                return (React.createElement(Button, { icon: faMinus, onClick: (event) => {
                        event.stopPropagation();
                        setState(new User({ ...stateRef.current, widgets: stateRef.current.widgets.filter((w, i) => i !== widgetIndex) }));
                    } }));
            }
        }
    ];
    return (React.createElement("div", { className: "MediaForm modalContent" },
        React.createElement("p", { className: "backButton pretitle", onClick: props.reject },
            React.createElement(FontAwesomeIcon, { icon: faChevronRight }),
            "Retour"),
        React.createElement("section", null,
            React.createElement(Row, null,
                React.createElement(Input, { label: "Nom", name: "userName", onInputChange: userName => stateRef.current.userName = userName, value: stateRef.current.userName, defaultValue: stateRef.current.userName }))),
        React.createElement("section", null,
            React.createElement(Row, null,
                React.createElement("h4", null, "Widgets"),
                React.createElement(Button, { icon: faPlus, onClick: () => {
                        context.modal({
                            form: (resolve, reject) => React.createElement(WidgetsModal, { resolve: resolve, reject: reject }),
                            raw: true,
                            className: "SyneviewModal"
                        })
                            .then((newWidget) => {
                            setState(new User({
                                ...stateRef.current,
                                widgets: [...stateRef.current.widgets, newWidget]
                            }));
                        });
                    } })),
            stateRef.current.widgets.length > 0 && (React.createElement(Row, { flex: true },
                React.createElement(Table, { flex: true, array: stateRef.current.widgets, head: widgetsHead, onClick: (widget, widgetIndex) => {
                        context.modal({
                            form: (resolve, reject) => React.createElement(WidgetsModal, { resolve: resolve, reject: reject, widget: widget }),
                            raw: true,
                            className: "SyneviewModal"
                        })
                            .then((newWidget) => {
                            setState(new User({
                                ...stateRef.current,
                                widgets: stateRef.current.widgets.map((w, i) => widgetIndex === i ? newWidget : w)
                            }));
                        });
                    } })))),
        React.createElement(OrganizationsSelect, { value: stateRef.current.organizations }),
        React.createElement("section", null,
            React.createElement(FormButtons, { onResolve: () => props.resolve(stateRef.current), onReject: props.reject }))));
};
const WidgetsModal = (props) => {
    const [state, setState] = useState(props.widget || {
        type: "clock",
        horizontalAlignment: "right",
        verticalAlignment: "bottom",
        horizontalMargin: 50,
        verticalMargin: 50,
        fontSize: 16,
    });
    return (React.createElement("div", { className: "WidgetForm modalContent" },
        React.createElement("p", { className: "backButton pretitle", onClick: props.reject },
            React.createElement(FontAwesomeIcon, { icon: faChevronRight }),
            "Retour"),
        React.createElement("section", null,
            React.createElement("h4", null, "Type"),
            React.createElement(TypeSelect, { buttons: [
                    { name: "Horloge", value: "clock" },
                    { name: "Météo", value: "meteo" },
                ], active: state.type, onChange: (type) => setState({ ...state, type }) })),
        React.createElement("section", null,
            React.createElement("h4", null, "Position"),
            React.createElement(Row, null,
                React.createElement(PositionSelector, { vertical: state.verticalAlignment, horizontal: state.horizontalAlignment, onChange: (verticalAlignment, horizontalAlignment) => setState({ ...state, verticalAlignment, horizontalAlignment }) })),
            React.createElement(Number, { name: "verticalMargin", label: "Marge verticale", value: String(state.verticalMargin), onInputChange: (verticalMargin) => setState({ ...state, verticalMargin: parseInt(verticalMargin) }) }),
            React.createElement(Number, { name: "horizontalMargin", label: "Marge horizontale", value: String(state.horizontalMargin), onInputChange: (horizontalMargin) => setState({ ...state, horizontalMargin: parseInt(horizontalMargin) }) }),
            React.createElement(Number, { name: "fontSize", label: "taille", value: String(state.fontSize), onInputChange: (fontSize) => setState({ ...state, fontSize: parseInt(fontSize) }) })),
        state.type == "meteo" && (React.createElement("section", null,
            React.createElement(Input, { name: "meteoLocation", value: state.meteoLocation, onInputChange: meteoLocation => setState({ ...state, meteoLocation }), label: "Localisation", delay: 5000 }))),
        React.createElement("section", { className: `${state.type}` },
            React.createElement("div", { className: "widgetBackground" },
                React.createElement(WidgetComponent, { widget: {
                        ...state,
                        verticalAlignment: "center",
                        horizontalAlignment: "center",
                        verticalMargin: 0,
                        horizontalMargin: 0,
                        fontSize: 32
                    } }))),
        React.createElement("section", null,
            React.createElement(FormButtons, { onResolve: () => props.resolve(state), onReject: props.reject }))));
};
