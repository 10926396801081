const br = 24;
const rr = 1;
const r = br * rr;
const minWidth = 96;
const maxWidth = 300;
const topPadding = 219;
const linkHeight = 48;
const linkMargin = 32;
export function getSVGPath(rect, active) {
    let path = `M ${br} 0 `;
    path += `h ${rect.width - br * 2} `;
    path += `a ${r} ${r} 0 0 1 ${br} ${br} `;
    path += `v ${topPadding - br * 2 + active * (linkMargin + linkHeight)} `;
    path += `a ${r} ${r} 0 0 1 -${br} ${br} `;
    path += `h -${rect.width - 16 - br * 2} `;
    path += `a ${r} ${r} 0 0 0 -${br} ${br} `;
    path += `v ${linkHeight - br * 2} `;
    path += `a ${r} ${r} 0 0 0 ${br} ${br} `;
    path += `h ${rect.width - 16 - br * 2} `;
    path += `a ${r} ${r} 0 0 1 ${br} ${br} `;
    path += `v ${rect.height - topPadding - linkHeight - br * 2 - active * (linkMargin + linkHeight)}`;
    path += `a ${r} ${r} 0 0 1 -${br} ${br} `;
    path += `h -${rect.width - br * 2} `;
    path += `a ${r} ${r} 0 0 1 -${br} -${br} `;
    path += `v -${rect.height - br * 2} `;
    path += `a ${r} ${r} 0 0 1 ${br} -${br} `;
    return path;
}
