import React, { useEffect, useRef, useState } from "react";
import "./Clock.scss";
export const Clock = (props) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const intervalRef = useRef(null);
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setCurrentDate(new Date());
        }, 10000);
        return () => {
            if (intervalRef.current)
                clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    });
    return (React.createElement("div", { className: "Clock", style: { fontSize: `${props.widget.fontSize}px` } },
        currentDate.getHours().toString().padStart(2, "0"),
        ":",
        currentDate.getMinutes().toString().padStart(2, "0")));
};
