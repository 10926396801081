import { User as UserBaseModel, Repository } from 'sunflowers';
const repositoryName = "user";
export class User extends UserBaseModel {
    constructor(user) {
        super(repositoryName, user);
        this.firstName = user.firstName || "";
        this.lastName = user.lastName || "";
        this.screenKey = user.screenKey;
        this.widgets = user.widgets || [];
    }
}
export class Users extends Repository {
    constructor(docs) {
        super(repositoryName, Users, User, docs);
    }
}
export default new Users();
