import React from "react"
import { render } from 'react-dom'
import App from './App'

//fonts
import "./style/ubuntu.scss";
import "./style/spartan.scss";

import "./style/colors.scss";
import "./style/text.scss";
import "./style/components.scss";
import "./style/main.scss";



render(
	<App />, document.getElementById("root")
)