export function superadminHead(columns, organisations, user) {
    if (user && user.roles.includes(1)) {
        return [
            ...columns,
            {
                label: "Organisations",
                key: "organizations",
                type: "function",
                sortable: true,
                function: (user) => user.organizations.reduce((string, orgaId, i, a) => {
                    const orga = organisations.findById(orgaId);
                    if (!orga)
                        return string;
                    return `${string}${orga.name}${i < a.length - 1 ? ", " : ""}
				`;
                }, "")
            }
        ];
    }
    else {
        return columns;
    }
}
