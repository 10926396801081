import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { faSun, faCloudSun, faCloudSunRain, faCloud, faCloudRain, faCloudShowersHeavy, faBolt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Meteo.scss";
export const Meteo = (props) => {
    const [response, setResponse] = useState(null);
    const intervalRef = useRef(null);
    const getMeteo = useCallback(() => {
        axios.get(`https://api.openweathermap.org/data/2.5/weather?q=${props.widget.meteoLocation}&appid=89b7a1132c9f31786c9c31c1e21fd2ef&lang=fr&units=metric`)
            .then(response => setResponse(response.data))
            .catch(() => { });
    }, [props.widget.meteoLocation]);
    useEffect(() => {
        if (intervalRef.current)
            clearInterval(intervalRef.current);
        intervalRef.current = null;
        getMeteo();
        intervalRef.current = setInterval(getMeteo, 60 * 60 * 1000);
        return () => {
            if (intervalRef.current)
                clearInterval(intervalRef.current);
            intervalRef.current = null;
        };
    }, [props.widget.meteoLocation]);
    const conditionCode = response?.weather[0]?.id || 800;
    return (React.createElement("div", { className: "Meteo", style: { fontSize: `${props.widget.fontSize}px` } },
        React.createElement("p", { className: "temp" },
            Math.round(response?.main.temp || 0),
            "\u00B0C"),
        React.createElement("div", { className: "conditions" },
            React.createElement(FontAwesomeIcon, { icon: conditionCode === 800 ? faSun
                    : conditionCode === 801 ? faCloudSun
                        : conditionCode >= 802 && conditionCode <= 804 ? faCloud
                            : conditionCode >= 200 && conditionCode <= 299 ? faBolt
                                : conditionCode >= 300 && conditionCode <= 399 ? faCloudSunRain
                                    : conditionCode >= 500 && conditionCode <= 501 ? faCloudRain
                                        : conditionCode >= 502 && conditionCode <= 599 ? faCloudShowersHeavy
                                            : faSun }),
            React.createElement("p", null,
                " ",
                response?.weather[0]?.description || "...")),
        React.createElement("p", { className: "minMax" },
            "Min ",
            Math.round(response?.main.temp_min || 0),
            "\u00B0C - Max ",
            Math.round(response?.main.temp_max || 0),
            "\u00B0C")));
};
