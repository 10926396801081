import React from "react";
import { Button, Row, Table } from "sunflowers";
import { faPlus, faPen, faPlay, faPause, faArchive } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { BroadcastGroup } from "../../models/BroadcastGroup.model";
import { BroadcastGroupForm } from './ressources/BroadcastGroupForm';
import { ArchiveAndRemovedButtons } from "../../components/ArchiveAndRemovedButtons/ArchiveAndRemovedButtons";
import { superadminHead } from "../../utils/superadminHead";
const Main = (view) => {
    const edit = (bg) => view.modal({
        form: (resolve, reject) => BroadcastGroupForm(resolve, reject, bg),
        className: "BroadcastGroupFormModal",
    })
        .then((patch) => bg.patch(patch)
        .send()
        .then(() => view.notify("Modifications sauvegardées.", "green"))
        .catch(({ error }) => view.notify(`Erreur: ${error}`, "red")));
    let head = [
        {
            label: "Nom",
            key: "name",
            type: "string",
            sortable: true,
        },
        {
            label: "Debut",
            type: "shortdate",
            key: "start",
            sortable: true,
        },
        {
            label: "Fin",
            type: "shortdate",
            key: "end",
            sortable: true,
        },
        {
            label: "Ecrans",
            type: "function",
            key: "screens",
            sortable: true,
            function: (bg) => {
                return bg.screens.length + (bg.screenGroups.length === 0
                    ? 0
                    : view.repositories.screenGroup
                        .filter(sg => bg.screenGroups.includes(sg._id))
                        .reduce((amount, sg) => amount + sg.screens.length, 0));
            }
        },
        {
            label: "Médias",
            type: "function",
            key: "medias",
            sortable: true,
            function: (bg) => bg.broadcasts.length,
        },
        {
            label: "Poids",
            key: "weight",
            type: "number",
            sortable: true,
        },
    ];
    head = superadminHead(head, view.repositories.organization, view.session.user);
    let mainHead = [
        ...head,
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (bg) => (React.createElement(Button, { icon: bg.playing ? faPause : faPlay, className: bg.playing ? "pauseButton" : "playButton", filled: true, onClick: (event) => {
                    event.stopPropagation();
                    bg.patch({ playing: bg.playing ? false : true })
                        .send()
                        .catch(() => {
                        view.notify("Erreur.", "red");
                    });
                } }))
        },
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (bg) => (React.createElement(Button, { className: "removeButton", filled: true, icon: faArchive, onClick: (event) => {
                    event.stopPropagation();
                    view.modal({
                        resolveButton: "Archiver",
                        rejectButton: "Annuler",
                        title: "Archiver un groupe de diffusions"
                    })
                        .then(() => {
                        bg.archive()
                            .send()
                            .then(() => view.notify("Groupe de diffusions archivé.", "green"))
                            .catch(() => view.notify("Echec.", "red"));
                    });
                } }))
        },
        {
            label: "",
            key: "name",
            type: "function",
            size: 4,
            function: (bg) => (React.createElement(Button, { icon: faPen, className: "actionButton", filled: true, onClick: () => edit(bg) }))
        }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "titleRow" },
            React.createElement("h3", null, "Groupes de diffusions"),
            React.createElement(ArchiveAndRemovedButtons, { head: head, repository: "broadcastGroup", archivesTitle: "Groupe de diffusions archiv\u00E9s", removedTitle: "Groupe de diffusions supprim\u00E9s", unarchiveTitle: () => "Désarchiver le groupe de diffusions", onUnarchiveSuccess: () => view.notify("Groupe de diffusions désarchivé.", "green"), onUnarchiveFail: () => view.notify("Echec.", "red"), removeTitle: () => "Supprimer le groupe de diffusions", onRemoveSuccess: () => view.notify("Groupe de diffusions supprimé.", "green"), onRemoveFail: () => view.notify("Echec.", "red"), restoreTitle: () => "Restaurer le groupe de diffusions", onRestoreSuccess: () => view.notify("Groupe de diffusions restauré.", "green"), onRestoreFail: () => view.notify("Echec.", "red"), destroyTitle: () => "Supprimer définitivement le groupe de diffusions", onDestroySuccess: () => view.notify("Groupe de diffusions supprimé définitivement.", "green"), onDestroyFail: () => view.notify("Echec.", "red"), emptyTrashTitle: "Vider la corbeille de groupes de diffusions ?", onEmptyTrashSuccess: () => view.notify("Corbeille vidée.", "green"), onEmptyTrashFail: () => view.notify("Echec.", "red") })),
        React.createElement("section", null,
            React.createElement(Button, { name: "Nouveau groupe de diffusions", icon: faPlus, className: "addButton", onClick: () => view.modal({
                    form: (resolve, reject) => BroadcastGroupForm(resolve, reject),
                    className: "BroadcastGroupFormModal",
                })
                    .then((bg) => view.repositories.broadcastGroup.post(new BroadcastGroup(bg))
                    .send()
                    .then(() => view.notify("Groupe de diffusion ajouté", "green"))
                    .catch(({ error }) => view.notify(`Erreur: ${error}`, "red"))) }),
            React.createElement(Table, { array: view.repositories.broadcastGroup, head: mainHead, flex: true, onClick: edit, remove: (bg) => view.modal({
                    title: "Supprimer un groupe de diffusions",
                    message: "Souhaitez vous réellement supprimer ce groupe de diffusion ?",
                    rejectButton: "Annuler",
                    resolveButton: "Supprimer"
                })
                    .then(() => {
                    bg.remove()
                        .send()
                        .then(() => view.notify("Groupe de diffusions supprimé.", "green"))
                        .catch(() => view.notify("Echec.", "red"));
                }) }))));
};
export default Main;
