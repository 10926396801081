import React, { useState } from "react";
import { NavigationMenu } from '../../components';
import { Profile } from "../Profile/Profile";
import "./LeftBar.scss";
import { LeftBarBackground } from "../LeftBarBackground/LeftBarBackground";
export const LeftBar = (view) => {
    const [state, setState] = useState({
        onMouseEnter: () => undefined,
        onMouseLeave: () => undefined,
    });
    return (React.createElement("div", { className: "LeftBarWrapper", onMouseEnter: state.onMouseEnter, onMouseLeave: state.onMouseLeave },
        React.createElement(LeftBarBackground, { setMouseCallbacks: (onMouseEnter, onMouseLeave) => setState({ ...state, onMouseEnter, onMouseLeave }) }),
        React.createElement("div", { className: "text" },
            React.createElement(Profile, null),
            React.createElement(NavigationMenu, { user: view.session.user, currentPath: view.router.path }))));
};
