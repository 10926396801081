import React from "react";
import { ViewComposer } from "sunflowers";
import models from "./models";
import { User } from "./models/User.model";
import views from './views';
export default function App() {
    return (React.createElement(ViewComposer, { views: views, defaultView: views[0], repositories: models, UserModel: User, socketURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? "http://127.0.0.1:3000"
            : "https://app.syneview.fr" }));
}
