// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/fonts/Spartan/Spartan-Light.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../assets/fonts/Spartan/Spartan-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Spartan\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-weight:lighter,100,200;font-style:normal}@font-face{font-family:\"Spartan\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");font-weight:normal,300,400,500,600,700,800,900;font-style:normal}", "",{"version":3,"sources":["webpack://./src/style/spartan.scss"],"names":[],"mappings":"AAGA,WACI,qBAAA,CACA,8DAAA,CACA,2BAAA,CACA,iBAAA,CAGJ,WACI,qBAAA,CACA,8DAAA,CACA,8CAAA,CACA,iBAAA","sourcesContent":["// SPARTAN\r\n// NORMAL\r\n\r\n@font-face {\r\n    font-family: 'Spartan';\r\n    src: url('../assets/fonts/Spartan/Spartan-Light.ttf') format('truetype');\r\n    font-weight: lighter, 100, 200;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n    font-family: 'Spartan';\r\n    src: url('../assets/fonts/Spartan/Spartan-Regular.ttf') format('truetype');\r\n    font-weight: normal, 300, 400 , 500, 600, 700, 800, 900;\r\n    font-style: normal;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
