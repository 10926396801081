import { Repository, Document } from 'sunflowers';
const repositoryName = "media";
export class Media extends Document {
    constructor(media) {
        super(repositoryName, media);
        this.name = media.name || "";
        this.type = media.type;
        this.fileExtension = media.fileExtension;
        this.url = media.url;
        this.duration = media.duration;
        this.filter_id = media.filter_id;
        this.template_id = media.template_id;
        this.topImage_id = media.topImage_id;
        this.bottomImage_id = media.bottomImage_id;
        this.imageFilter = media.imageFilter;
    }
}
export class Medias extends Repository {
    constructor(docs) {
        super(repositoryName, Medias, Media, docs);
    }
}
export default new Medias();
