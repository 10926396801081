import React from "react";
import { Link } from 'sunflowers';
import views from '../../views';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './NavigationMenu.scss';
export const NavigationMenu = (props) => {
    return (React.createElement("ul", { className: "NavigationMenu" }, props.user && views
        .filter(view => view.menu
        && (!view.roles
            || view.roles.length === 0
            || view.roles.some(role => props.user?.roles.includes(role)))).map((view, i) => (React.createElement(Link, { key: i, className: Array.isArray(view.path)
            ? view.path.includes(props.currentPath)
                ? "active"
                : ""
            : view.path === props.currentPath
                ? "active"
                : "", href: typeof view.path === "string" ? view.path : view.path[0] },
        React.createElement("div", null,
            React.createElement("p", null, view.title),
            React.createElement("span", null, view.icon && React.createElement(FontAwesomeIcon, { icon: view.icon }))))))));
};
