import React from "react";
import { Button, Row } from 'sunflowers';
import "./FormButtons.scss";
export const FormButtons = (props) => {
    const resolveButton = props.resolveButton || "Enregistrer";
    const rejectButton = props.rejectButton || "Annuler";
    return (React.createElement(Row, { className: `FormButtons ${props.className}` },
        React.createElement(Button, { className: "rejectButton", name: rejectButton, onClick: props.onReject }),
        React.createElement(Button, { className: "resolveButton", name: resolveButton, onClick: props.onResolve })));
};
