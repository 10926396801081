export function getYouTubeVideoId(url) {
    if (url.includes("/") || url.includes("=")) {
        const lastSlashIndex = url.lastIndexOf("/");
        const lastEqualIndex = url.lastIndexOf("=");
        const lastChar = lastSlashIndex > lastEqualIndex ? "/" : "=";
        const splittedString = url.split(lastChar);
        return splittedString[splittedString.length - 1];
    }
    else {
        return url;
    }
}
