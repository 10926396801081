import React from "react";
import { faAlignLeft, faAlignRight, faAlignCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./PositionSelector.scss";
const verticalAlignments = ["top", "center", "bottom"];
export const PositionSelector = (props) => {
    return (React.createElement("div", { className: "PositionSelector" }, verticalAlignments.map((va, i) => (React.createElement("div", { key: i, className: `vertical ${va}` },
        React.createElement("div", { className: `${va} left ${props.vertical === va && props.horizontal === "left" && "active"}`, onClick: () => props.onChange(va, "left") },
            React.createElement(FontAwesomeIcon, { icon: faAlignLeft })),
        React.createElement("div", { className: `${va} center ${props.vertical === va && props.horizontal === "center" && "active"}`, onClick: () => props.onChange(va, "center") },
            React.createElement(FontAwesomeIcon, { icon: faAlignCenter })),
        React.createElement("div", { className: `${va} right ${props.vertical === va && props.horizontal === "right" && "active"}`, onClick: () => props.onChange(va, "right") },
            React.createElement(FontAwesomeIcon, { icon: faAlignRight })))))));
};
