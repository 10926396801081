import React, { useState } from "react";
import { Button } from "sunflowers";
import "./FileInput.scss";
const ref = React.createRef();
export const FileInput = (props) => {
    const [selectedFile, setSelectedFile] = useState();
    const handleChange = (e) => {
        if (!e || !e.target || !e.target.files || !e.target.files[0])
            return;
        const file = e.target.files[0];
        setSelectedFile(file);
        props.onChange && props.onChange(file);
    };
    return (React.createElement("div", { className: "FileInput" },
        React.createElement(Button, { name: props.name, icon: props.icon, onClick: () => ref.current?.click() }),
        React.createElement("input", { type: "file", name: props.name, onChange: handleChange, ref: ref })));
};
