import React, { useEffect, useState } from "react";
import { dayFRtoEN } from "../../assets/constants/weekdays";
import "./TimeSlotsPicker.scss";
const minutesStep = 60;
const hourStringsStep = 2;
const daysEN = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
const days = [
    {
        name: "lundi",
        hours: []
    },
    {
        name: "mardi",
        hours: []
    },
    {
        name: "mercredi",
        hours: []
    },
    {
        name: "jeudi",
        hours: []
    },
    {
        name: "vendredi",
        hours: []
    },
    {
        name: "samedi",
        hours: []
    },
    {
        name: "dimanche",
        hours: []
    },
];
days.forEach(day => {
    for (let minute = 0; minute < 1440; minute += minutesStep) {
        day.hours.push({ start: minute, selected: false });
    }
});
function minutesToHourString(m) {
    const min = m % 60;
    const h = (m - min) / 60;
    return `${h}h${String(min).padStart(2, "0")}`;
}
function convertTimeSlots(timeSlots) {
    const convertedTimeSlots = [];
    let temp_timeSlot = null;
    const pushTempTimeSlot = (end) => {
        if (temp_timeSlot) {
            convertedTimeSlots.push({ ...temp_timeSlot, end });
            temp_timeSlot = null;
        }
    };
    timeSlots.forEach(day => {
        day.hours.forEach(hour => {
            if (hour.selected) {
                if (!temp_timeSlot) {
                    temp_timeSlot = { day: dayFRtoEN[day.name], start: hour.start };
                }
            }
            else if (temp_timeSlot) {
                pushTempTimeSlot(hour.start);
            }
        });
        pushTempTimeSlot(1440);
    });
    return convertedTimeSlots;
}
function revertTimeSlots(timeSlots) {
    let reversedTimeSlots = days;
    reversedTimeSlots = reversedTimeSlots.map(day => {
        return {
            name: day.name,
            hours: day.hours.map(hour => {
                if (timeSlots.some(ts => ts.day === dayFRtoEN[day.name]
                    && ts.start < hour.start + minutesStep
                    && ts.end > hour.start)) {
                    console.log("JOUR", day.name);
                    return { ...hour, selected: true };
                }
                else {
                    return { ...hour, selected: false };
                }
            })
        };
    });
    return reversedTimeSlots;
}
export const TimeSlotsPicker = (props) => {
    const [timeSlots, setTimeSlots] = useState([]);
    useEffect(() => setTimeSlots(revertTimeSlots(props.timeSlots)), [props.timeSlots]);
    const [action, setAction] = useState(0);
    function handleChange(dayIndex, hourIndex, selected) {
        const newTimeSlots = timeSlots.map((day, dayI) => {
            if (dayIndex === dayI) {
                return {
                    name: day.name,
                    hours: day.hours.map((hour, hourI) => {
                        if (hourI === hourIndex) {
                            return {
                                start: hour.start,
                                selected
                            };
                        }
                        else {
                            return hour;
                        }
                    })
                };
            }
            else {
                return day;
            }
        });
        setTimeSlots(newTimeSlots);
        props.onChange && props.onChange(convertTimeSlots(newTimeSlots));
    }
    return (React.createElement("div", { className: "TimeSlotsPicker", draggable: "false" },
        React.createElement("div", { className: "hoursColumn", draggable: "false" }, days[0].hours.map((h, i) => (React.createElement("div", { className: "timeSlot", draggable: "false", key: i }, i % hourStringsStep === 0 && minutesToHourString(h.start))))),
        timeSlots.map((day, dayI) => (React.createElement("div", { className: "dayColumn", draggable: "false", key: dayI },
            React.createElement("div", { className: "dayName", draggable: "false" }, day.name),
            day.hours.map((hour, hourI) => (React.createElement("div", { key: hourI, className: `timeSlot${hour.selected ? " selected" : ""}`, onMouseDown: () => {
                    handleChange(dayI, hourI, !hour.selected);
                    setAction(hour.selected ? -1 : 1);
                }, onMouseEnter: (event) => event.buttons === 1 && handleChange(dayI, hourI, action === 1), draggable: "false" }))))))));
};
