import React, { useContext, useState } from "react";
import { Button, Context, Input, Row, Pagination } from 'sunflowers';
import { faPlus, faTrash, faPen, faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Media } from "../../models/Media.model";
import "./MediasLibrary.scss";
import { FileInput } from "../FileInput/FileInput";
import { MediaComponent } from "../MediaComponent/MediaComponent";
import { ArchiveAndRemovedButtons } from "../ArchiveAndRemovedButtons/ArchiveAndRemovedButtons";
import { getYouTubeVideoId } from "../../utils/getYouTubeVideoId";
import { getVideoDuration } from "../../utils/getVideoDuration";
import Promise from "true-promise";
import { commaOrAnd } from "../../utils/commaOrAnd";
import { OrganizationsSelect } from "../../components/OrganizationsSelect/OrganizationsSelect";
import { TypeSelect } from "../TypeSelect/TypeSelect";
const ref = React.createRef();
const videoFormats = ["mp4", "ogg", "webm"];
const imageFormats = ["png", "jpg", "jpeg"];
const mediaPerPage = 12;
export const MediasLibrary = (props) => {
    const [state, setState] = useState({ page: 1 });
    const context = useContext(Context);
    const mediaHead = [
        {
            label: "nom",
            key: "name",
            type: "string",
            sortable: true,
        },
        {
            label: "type",
            key: "type",
            type: "string",
            sortable: true,
        },
        {
            label: "Aperçu",
            key: "name",
            type: "function",
            function: (media) => (React.createElement("div", { className: "mediaCell" },
                React.createElement(MediaComponent, { media: media, library: true })))
        }
    ];
    const Tag = props.standalone ? "section" : "div";
    const fileUpload = async (data, callback) => {
        if (!data.file || !context.post)
            return Promise.reject("Erreur.");
        const formData = new FormData();
        formData.append('media', data.file);
        formData.append("name", data.name);
        if (data._id) {
            formData.append("_id", data._id);
        }
        const type = data.file.type.split("/")[0];
        let duration;
        if (type === "video") {
            duration = await getVideoDuration(data.file);
            if (!(typeof duration === "number"))
                return Promise.reject("Erreur.");
            formData.append('duration', String(duration));
        }
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        return context.post("fileUpload", formData, config);
    };
    const edit = (media) => {
        context.modal && context.modal({
            className: "SyneviewModal NewMediaModal",
            raw: true,
            form: (resolve, reject) => React.createElement(MediaForm, { resolve: resolve, reject: reject, media: media })
        })
            .then((patch) => {
            if (patch.file) {
                context.repositories?.media.findById(media._id)?.patch({ type: "", fileExtension: "" });
                fileUpload({ ...patch, _id: media._id })
                    .then(() => {
                    context.notify && context.notify("Modifications sauvegardées.", "green");
                    context.repositories?.media.findById(media._id)?.patch({
                        type: media.type,
                        fileExtension: media.fileExtension,
                        ...patch
                    });
                })
                    .catch(() => context.notify && context.notify("Echec.", "red"));
            }
            else {
                media.patch(patch)
                    .send()
                    .then(() => context.notify && context.notify("Modifications sauvegardées.", "green"))
                    .catch(() => context.notify && context.notify("Echec.", "red"));
            }
        });
    };
    const array = context.repositories?.media.filter((media) => !props.selectOnly || props.selectOnly.includes(media.type)) || [];
    const page = (state.page || 1) - 1;
    let firstRowIndex = !mediaPerPage ? 0 : page * mediaPerPage;
    let lastRowIndex = firstRowIndex + (mediaPerPage || array.length);
    firstRowIndex = firstRowIndex > array.length ? array.length : firstRowIndex;
    lastRowIndex = lastRowIndex > array.length ? array.length : lastRowIndex;
    const slicedArray = array.slice(firstRowIndex, lastRowIndex);
    return (React.createElement("div", { className: "MediasLibrary" },
        !props.standalone && (React.createElement("p", { className: "backButton pretitle", onClick: props.reject },
            React.createElement(FontAwesomeIcon, { icon: faChevronRight }),
            "Retour")),
        !props.selectOnly && (React.createElement(Row, { className: "titleRow" },
            React.createElement("h3", null, "Biblioth\u00E8que"),
            React.createElement(ArchiveAndRemovedButtons, { head: mediaHead, repository: "media", archivesTitle: "M\u00E9dias archiv\u00E9s", removedTitle: "M\u00E9dias supprim\u00E9s", unarchiveTitle: () => "Désarchiver le média", onUnarchiveSuccess: () => context.notify && context.notify("Média désarchivé.", "green"), onUnarchiveFail: () => context.notify && context.notify("Echec.", "red"), removeTitle: () => "Supprimer le média", onRemoveSuccess: () => context.notify && context.notify("Média supprimé.", "green"), onRemoveFail: () => context.notify && context.notify("Echec.", "red"), restoreTitle: () => "Restaurer le média", onRestoreSuccess: () => context.notify && context.notify("Média restauré.", "green"), onRestoreFail: () => context.notify && context.notify("Echec.", "red"), destroyTitle: () => "Supprimer définitivement le média", onDestroySuccess: () => context.notify && context.notify("Média supprimé définitivement.", "green"), onDestroyFail: () => context.notify && context.notify("Echec.", "red"), emptyTrashTitle: "Vider la corbeille de média ?", onEmptyTrashSuccess: () => context.notify && context.notify("Corbeille vidée.", "green"), onEmptyTrashFail: () => context.notify && context.notify("Echec.", "red") }))),
        React.createElement(Tag, { className: "section" },
            !props.selectOnly && (React.createElement(Row, { className: "topRow" },
                React.createElement(Button, { icon: faPlus, onClick: () => {
                        context.modal && context.modal({
                            className: "SyneviewModal NewMediaModal",
                            raw: true,
                            form: (resolve, reject) => React.createElement(MediaForm, { resolve: resolve, reject: reject })
                        })
                            .then(formData => {
                            if (formData.file) {
                                fileUpload(formData)
                                    .then((response) => {
                                    context.notify && context.notify("Fichier mis en ligne.", "green");
                                    context.repositories?.media.post(new Media({
                                        _id: response._id,
                                        name: formData.name,
                                        type: formData.type,
                                        fileExtension: formData.fileExtension
                                    }));
                                })
                                    .catch(() => context.notify && context.notify("Echec.", "red"));
                            }
                            else {
                                context.repositories?.media.post(new Media(formData))
                                    .send()
                                    .then(() => context.notify && context.notify("Média ajouté.", "green"))
                                    .catch(() => context.notify && context.notify("Echec.", "red"));
                            }
                        });
                    } }),
                React.createElement(Row, null,
                    React.createElement("p", null, "Page :"),
                    React.createElement(Pagination, { pageAmount: Math.ceil((context.repositories?.media.length || 0) / mediaPerPage), page: state.page, onClick: (page) => setState({ page }), before: 3, after: 3, firstButton: true, lastButton: true, previousButton: "<", nextButton: ">", secondarySeparator: "...", balance: true })))),
            React.createElement("div", { className: "mediasGrid", ref: ref }, slicedArray.map((media) => {
                const organizations = context.repositories?.organization?.filter(o => media.organizations.includes(o._id));
                const orgaString = organizations?.reduce((string, orga, i, a) => `${string}${orga.name}${commaOrAnd(i, a.length)}`, "");
                return (React.createElement("div", { key: media._id, className: "media", onClick: (event) => {
                        if (props.standalone) {
                            edit(media);
                        }
                        else if (props.onSelect) {
                            props.onSelect(media);
                        }
                    } },
                    React.createElement(MediaComponent, { media: media, library: true }),
                    React.createElement("div", { className: "overlay" },
                        React.createElement("p", { className: "name" },
                            media.type.toUpperCase(),
                            media.name ? ` - ${media.name}` : "",
                            media.type === "video" && media.duration ? ` - ${Math.floor(media.duration)}s` : ""),
                        React.createElement(Row, { className: 'buttons' },
                            context.session?.user.roles.includes(1) && orgaString && (React.createElement("p", { className: "organizations" }, orgaString)),
                            React.createElement(Button, { icon: faPen, onClick: (event) => {
                                    event.stopPropagation();
                                    edit(media);
                                } }),
                            React.createElement(Button, { icon: faTrash, onClick: (event) => {
                                    event.stopPropagation();
                                    context.modal && context.modal({
                                        title: "Supprimer ce média ?",
                                        rejectButton: "Annuler",
                                        resolveButton: "Supprimer"
                                    })
                                        .then(() => {
                                        media.remove()
                                            .send()
                                            .then(() => { })
                                            .catch(() => { });
                                    });
                                } })))));
            })))));
};
export const mediasLibraryModal = (modal, selectOnly) => modal({
    className: "SyneviewModal MediasLibraryModal",
    raw: true,
    form: (resolve, reject) => (React.createElement("div", { className: "modalContent" },
        React.createElement(MediasLibrary, { onSelect: resolve, reject: reject, selectOnly: selectOnly })))
});
export const MediaForm = (props) => {
    const [state, setState] = useState({
        oldMediaId: props.media?._id || null,
        file: null,
        fileTempURL: null,
        usingURL: Boolean(props.media?.url),
        name: props.media?.name || "",
        type: props.media?.type || "image",
        filter_id: props.media?.filter_id ? String(props.media?.filter_id) : "",
        url: props.media?.url || "",
        fileExtension: props.media?.fileExtension || null,
        organizations: props.media?.organizations || [],
        topImage_id: props.media?.topImage_id || null,
        bottomImage_id: props.media?.bottomImage_id || null,
        imageFilter: props.media?.imageFilter || ""
    });
    const context = useContext(Context);
    const handleTypeChange = (type) => {
        setState({ ...state, type, url: "", filter_id: "", file: null, usingURL: false, fileTempURL: null, oldMediaId: null });
    };
    const handleFileChange = (file) => {
        const type = file.type.split("/")[0];
        const split = file.name.split(".");
        const fileExtension = String(split[split.length - 1]).toLowerCase();
        const fileTempURL = URL.createObjectURL(file);
        setState({ ...state, file, type, fileExtension, fileTempURL });
    };
    const handleImageURLChange = (url) => {
        const split = url.split(".");
        const fileExtension = split[split.length - 1].toLowerCase();
        const type = videoFormats.includes(fileExtension) ? "video" : "image";
        setState({ ...state, fileExtension, type, url });
    };
    const canSave = (props.media && (state.name !== props.media.name || (state.url && state.url !== props.media.url) || state.file))
        || ((state.type === "image" || state.type === "video") && ((!state.usingURL && state.file)
            || (state.usingURL && state.url)))
        || state.url;
    return (React.createElement("div", { className: "MediaForm modalContent" },
        React.createElement("p", { className: "backButton pretitle", onClick: props.reject },
            React.createElement(FontAwesomeIcon, { icon: faChevronRight }),
            "Retour"),
        React.createElement("h3", null, "M\u00E9dia"),
        React.createElement(TypeSelect, { label: "type:", buttons: [
                { name: "image/video", value: "image" },
                { name: "synapi", value: "synapi" },
                { name: "youtube", value: "youtube" },
                { name: "iframe", value: "iframe" },
            ], onChange: handleTypeChange, active: state.type === "video" ? "image" : state.type }),
        React.createElement(Row, { flex: true },
            React.createElement(Input, { name: "name", label: "Nom", onInputChange: name => setState({ ...state, name }), value: state.name })),
        (state.type === 'image' || state.type === "video") && (React.createElement(Row, { flex: true }, !state.file && (React.createElement(React.Fragment, null,
            !state.usingURL && (React.createElement(React.Fragment, null,
                React.createElement(FileInput, { onChange: handleFileChange, name: props.media ? "Changer de média" : "Uploader un média" }),
                React.createElement(Button, { className: "actionButton", filled: true, name: "\u00C0 partir d'une URL", onClick: () => {
                        setState({ ...state, usingURL: true, oldMediaId: null });
                    } }))),
            state.usingURL && (React.createElement(React.Fragment, null,
                React.createElement(Input, { name: "url", label: "URL", className: "url file", value: state.url, onInputChange: handleImageURLChange }),
                React.createElement(Button, { icon: faTimes, onClick: () => setState({ ...state, usingURL: false, url: "", oldMediaId: props.media?._id || null }) }))))))),
        (state.type === "synapi" || state.type === "youtube" || state.type === "iframe") && (React.createElement(React.Fragment, null,
            React.createElement(Row, { flex: true },
                React.createElement(Input, { name: "url", label: state.type === "synapi" ? "url de base (https://synapi.officedetourisme.com)" : "URL", onInputChange: url => setState({ ...state, url }), value: state.url }),
                state.type === "synapi" && (React.createElement(Input, { name: "filter_id", label: "ID du filtre de s\u00E9lection", onInputChange: filter_id => setState({ ...state, filter_id }), value: state.filter_id }))),
            state.type === "synapi" && (React.createElement(Row, { flex: true },
                React.createElement(Input, { name: "imageFilter", label: "Filtrage des images par cl\u00E9", onInputChange: imageFilter => setState({ ...state, imageFilter }), value: state.imageFilter }))),
            state.type === "synapi" && state.url && (React.createElement(Row, null,
                React.createElement("span", null, "Appel API \u00E9mis : "),
                React.createElement("span", null,
                    state.url,
                    "/api/items",
                    state.filter_id && (React.createElement("span", null,
                        "?id=",
                        state.filter_id)),
                    state.filter_id && state.imageFilter && (React.createElement("span", null,
                        "&imageFilter=",
                        state.imageFilter))))),
            state.type === "synapi" && (React.createElement(Row, { flex: true },
                React.createElement(Button, { name: "Banni\u00E8re sup\u00E9rieure", className: "actionButton", onClick: () => context.modal && mediasLibraryModal(context.modal, ["image"])
                        .then((media) => {
                        setState({ ...state, topImage_id: media._id });
                    }) }),
                React.createElement(Button, { name: "Banni\u00E8re inf\u00E9rieure", className: "actionButton", onClick: () => context.modal && mediasLibraryModal(context.modal, ["image"])
                        .then((media) => {
                        setState({ ...state, bottomImage_id: media._id });
                    }) }))))),
        (state.oldMediaId
            || ((state.type === "image" || state.type === "video")
                && ((!state.usingURL && Boolean(state.fileTempURL))
                    || (state.usingURL && Boolean(state.url))))
            || (state.url && state.url.length > 0)) && (React.createElement("div", { className: "media" },
            React.createElement(MediaComponent, { media: new Media({
                    _id: state.oldMediaId || "",
                    url: state.url ? state.type === "youtube" ? getYouTubeVideoId(state.url) : state.url : state.fileTempURL || null,
                    name: state.name,
                    type: state.type || "image",
                    filter_id: parseInt(state.filter_id),
                    fileExtension: state.fileExtension || "",
                    topImage_id: state.topImage_id || undefined,
                    bottomImage_id: state.bottomImage_id || undefined,
                    imageFilter: state.imageFilter || "",
                }), library: true }),
            ((state.oldMediaId && !state.url) || state.file) && (React.createElement(Button, { icon: faTimes, onClick: () => setState({ ...state, file: null, fileTempURL: null, oldMediaId: null }) })))),
        React.createElement(OrganizationsSelect, { value: state.organizations, onChange: organizations => setState({ ...state, organizations }) }),
        React.createElement(SaveButtons, { media: props.media, disabled: !canSave, resolve: () => {
                const response = {
                    name: state.name,
                    type: state.type
                };
                if (state.type === "image" || state.type === "video") {
                    response.fileExtension = state.fileExtension || "";
                    if (state.usingURL) {
                        response.url = state.url;
                    }
                    else if (state.file) {
                        response.file = state.file;
                        response.url = null;
                    }
                }
                else {
                    if (state.type === "youtube") {
                        response.url = getYouTubeVideoId(state.url);
                    }
                    else {
                        response.url = state.url;
                        if (state.type === "synapi") {
                            response.filter_id = parseInt(state.filter_id);
                            response.topImage_id = state.topImage_id || undefined;
                            response.bottomImage_id = state.bottomImage_id || undefined;
                            response.imageFilter = state.imageFilter || undefined;
                        }
                    }
                }
                if (context.session?.user.roles.includes(1)) {
                    response.organizations = state.organizations;
                }
                props.resolve(response);
            }, reject: props.reject })));
};
const SaveButtons = props => {
    const context = useContext(Context);
    return (React.createElement(Row, { className: "buttonsRow" },
        React.createElement(Button, { name: 'Annuler', className: 'cancelButton', onClick: props.reject }),
        React.createElement(Button, { name: props.media ? "Sauvegarder" : "Ajouter", className: 'addButton', disabled: props.disabled, onClick: () => {
                if (!props.media) {
                    props.resolve();
                }
                else if (!props.disabled && context.modal) {
                    context.modal({
                        title: "Modification d'un média",
                        message: `Êtes-vous certain·e de vouloir modifier ${props.media && props.media.name?.length > 0 ? `le média "${props.media?.name}"` : "ce média"} ? Cela affectera tous les groupes de diffusions auxquels il est affecté.`,
                        resolveButton: "Confirmer",
                        rejectButton: "Annuler"
                    })
                        .then(() => setTimeout(props.resolve));
                }
            } })));
};
